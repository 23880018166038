import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Theme } from "@material-ui/core";
import { ModelType, PredictionModel, getModelTypeName, modelThresholdsFields } from "../../models/utils";
import { PredictionConfiguration, PredictionResultConfig } from "../../models/config";
import React, { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import { ConfigurationProps } from "./ConfigurationPanel";
import ConfigurationSection from "./ConfigurationSection";
import ConfigurationSubsection from "./ConfigurationSubsection";
import { FilteredType } from "../../utils/types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GlobalContext } from "../../globalContext";
import { ModelPredictionsSelector } from "./ModelPredictionsSelector";
import { SliderBar } from "../Utils/SliderBar";
import { SwitchElement } from "../Utils/SwitchElement";

const FORMULA_SECTION_DESCRIPTION =
  "Models to be used in the formula used to determine whether the prediction was real, fake or undetermined";
const MODELS_THRESHOLD_SECTION_DESCRIPTION = "Threholds used by each model to determine the prediction result";
const MTCNN_DESCRIPTION_DESCRIPTION = "Configuration for the MTCNN model used by the AI Services";
const SAVE_IMAGES_DESCRIPTION_DESCRIPTION = "Configuration used to hide or show the store images option";
const RESULT_SECTION_DESCRIPTION = "Customization options to modify the scan result page";

enum PredictionSection {
  Formula = "Formula",
  ModelsThreshold = "Models Threshold",
  Result = "Result",
  MTCNN = "MTCNN",
  SaveImages = "Save Images",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableColumn: {
      textAlign: "left",
      padding: "8px",
    },
    mtcnnMinFaceSizeSlider: {
      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
    },
  }),
);

export const PredictionPanel: React.FC<ConfigurationProps<PredictionConfiguration>> = (props) => {
  const classes = useStyles();

  const { isAdmin, onConfigurationChange, config } = props;
  const section = config.predictionConfiguration;

  const setPanelConfigurationSlider = (value: number, name: keyof PredictionConfiguration) => {
    if (!name) return;
    onConfigurationChange({
      ...section,
      [name]: value,
    });
  };

  const { panelConfigurationData } = useContext(GlobalContext);

  const onChangeModelFormula = (index: number, key: ModelType, requested: boolean, inFormula: boolean) => {
    const requestedModels = JSON.parse(
      JSON.stringify(config.predictionConfiguration.requestedModels),
    ) as PredictionModel[];
    if (index === -1) {
      requestedModels.push({
        key: key,
        inFormula: false,
        antiSpoofing: panelConfigurationData.predictionConfiguration.predictionResult.some(
          (m) => m.model === key && m.antiSpoofing,
        ),
      });
    } else if (!requested) {
      requestedModels.splice(index, 1);
    } else {
      requestedModels[index].inFormula = inFormula;
    }

    // Sort the elements in the list because the application compares the values
    // to enable or disable the save button.
    const newRequestedModels = [...requestedModels].sort((a, b) => a.key.localeCompare(b.key));
    onConfigurationChange({
      ...section,
      requestedModels: newRequestedModels,
    });
  };

  const onPredictionResultChange = (config: PredictionResultConfig[]) => {
    onConfigurationChange({
      ...section,
      predictionResult: config,
    });
  };

  const tfModelThresholds: Array<{
    field: keyof FilteredType<PredictionConfiguration, number>;
    modelType: ModelType;
    min?: number;
    max?: number;
    step?: number;
  }> = modelThresholdsFields.map((m) => {
    if (m.modelType === ModelType.FIQA) {
      return { min: 0.001, max: 5.0, step: 0.001, ...m };
    } else {
      return m;
    }
  });

  const modelIndex: number[] = [];
  const requested: boolean[] = [];
  const inFormula: boolean[] = [];
  Object.values(ModelType).forEach((key) => {
    const index = section.requestedModels.findIndex((model) => model.key === key);
    modelIndex.push(index);
    requested.push(index >= 0);
    inFormula.push(index >= 0 && section.requestedModels[index].inFormula);
  });

  return (
    <div>
      <ConfigurationSection title={PredictionSection.Formula} description={FORMULA_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Formula configuration">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableColumn}>Model</TableCell>
                <TableCell className={classes.tableColumn}>Requested</TableCell>
                <TableCell className={classes.tableColumn}>In formula</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(ModelType).map((k, i) => {
                const key = k as ModelType;
                if (i < Object.values(ModelType).length) {
                  return (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row" className={classes.tableColumn}>
                        {getModelTypeName(key)}
                      </TableCell>
                      <TableCell className={classes.tableColumn}>
                        <Checkbox
                          checked={requested[i]}
                          onChange={() => {
                            onChangeModelFormula(modelIndex[i], key, !requested[i], inFormula[i]);
                          }}
                          color="primary"
                          disabled={!isAdmin}
                        />
                      </TableCell>
                      <TableCell className={classes.tableColumn}>
                        {panelConfigurationData.predictionConfiguration.predictionResult.some(
                          (m) => m.model === key && m.antiSpoofing,
                        ) && (
                          <Checkbox
                            checked={inFormula[i]}
                            onChange={() => {
                              onChangeModelFormula(modelIndex[i], key, requested[i], !inFormula[i]);
                            }}
                            disabled={!requested[i] || !isAdmin}
                            color="primary"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return <div key={i}></div>;
                }
              })}
            </TableBody>
          </Table>
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection
        title={PredictionSection.ModelsThreshold}
        description={MODELS_THRESHOLD_SECTION_DESCRIPTION}
      >
        <Grid container spacing={10}>
          {tfModelThresholds.map((item) => (
            <Grid item xs={12} md={6} key={`tf-threshold-${item.modelType}`}>
              <ConfigurationSubsection title={getModelTypeName(item.modelType)} />
              <SliderBar
                min={item.min || 0.001}
                max={item.max || 0.999}
                step={item.step || 0.001}
                value={section[item.field]}
                onChange={(v) => setPanelConfigurationSlider(v, item.field)}
                disabled={!isAdmin}
              />
            </Grid>
          ))}
          <Grid xs={12} md={6} item key={`tf-threshold-rgb-fas-confidence-threshold`}>
            <ConfigurationSubsection title="RGB FAS V2 Confidence Threshold">
              <SliderBar
                min={0.001}
                max={0.999}
                step={0.001}
                value={section.rgbFASV2ConfidenceThreshold}
                onChange={(v) => setPanelConfigurationSlider(v, "rgbFASV2ConfidenceThreshold")}
                disabled={!isAdmin}
              />
            </ConfigurationSubsection>
          </Grid>
          <Grid xs={12} md={6} item key={`tf-threshold-meta-fas-confidence-threshold`}>
            <ConfigurationSubsection title="MetaFAS Confidence Threshold">
              <SliderBar
                min={0}
                max={1}
                step={0.001}
                value={section.metaFASConfidenceThreshold}
                onChange={(v) => setPanelConfigurationSlider(v, "metaFASConfidenceThreshold")}
                disabled={!isAdmin}
              />
            </ConfigurationSubsection>
          </Grid>
          <Grid xs={12} md={6} item key={`tf-threshold-meta-fas-v2-confidence-threshold`}>
            <ConfigurationSubsection title="MetaFAS V2 Confidence Threshold">
              <SliderBar
                min={0}
                max={1}
                step={0.001}
                value={section.metaFASV2ConfidenceThreshold}
                onChange={(v) => setPanelConfigurationSlider(v, "metaFASV2ConfidenceThreshold")}
                disabled={!isAdmin}
              />
            </ConfigurationSubsection>
          </Grid>
          <Grid xs={12} md={6} item key={`tf-threshold-meta-fas-v2-b-confidence-threshold`}>
            <ConfigurationSubsection title="MetaFAS V2 B Confidence Threshold">
              <SliderBar
                min={0}
                max={1}
                step={0.001}
                value={section.metaFASV2BConfidenceThreshold}
                onChange={(v) => setPanelConfigurationSlider(v, "metaFASV2BConfidenceThreshold")}
                disabled={!isAdmin}
              />
            </ConfigurationSubsection>
          </Grid>
          <Grid xs={12} md={6} item key={`tf-threshold-meta-fas-v3-confidence-threshold`}>
            <ConfigurationSubsection title="MetaFAS V3 Confidence Threshold">
              <SliderBar
                min={0}
                max={1}
                step={0.001}
                value={section.metaFASV3ConfidenceThreshold}
                onChange={(v) => setPanelConfigurationSlider(v, "metaFASV3ConfidenceThreshold")}
                disabled={!isAdmin}
              />
            </ConfigurationSubsection>
          </Grid>
          <Grid xs={12} md={6} item key={`tf-threshold-meta-fas-v4-confidence-threshold`}>
            <ConfigurationSubsection title="MetaFAS V4 Confidence Threshold">
              <SliderBar
                min={0}
                max={1}
                step={0.001}
                value={section.metaFASV4ConfidenceThreshold}
                onChange={(v) => setPanelConfigurationSlider(v, "metaFASV4ConfidenceThreshold")}
                disabled={!isAdmin}
              />
            </ConfigurationSubsection>
          </Grid>
          <Grid xs={12} md={6} item key={`tf-threshold-meta-fas-v5-confidence-threshold`}>
            <ConfigurationSubsection title="MetaFAS V5 Confidence Threshold">
              <SliderBar
                min={0}
                max={1}
                step={0.001}
                value={section.metaFASV5ConfidenceThreshold}
                onChange={(v) => setPanelConfigurationSlider(v, "metaFASV5ConfidenceThreshold")}
                disabled={!isAdmin}
              />
            </ConfigurationSubsection>
          </Grid>
        </Grid>
      </ConfigurationSection>

      <ConfigurationSection title={PredictionSection.Result} description={RESULT_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="General preferences">
          <FormControlLabel
            control={
              <Checkbox
                checked={section.showPrediction}
                onChange={(_, checked) =>
                  onConfigurationChange({
                    ...section,
                    showPrediction: checked,
                  })
                }
                color="primary"
                disabled={!isAdmin}
              />
            }
            label="Show prediction"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={section.showFeedback}
                onChange={(_, checked) =>
                  onConfigurationChange({
                    ...section,
                    showFeedback: checked,
                  })
                }
                color="default"
                disabled={!isAdmin}
              />
            }
            label="Show user feedback"
          />
        </ConfigurationSubsection>
        <ConfigurationSubsection title="Models prediction result preferences">
          <ModelPredictionsSelector
            isAdmin={isAdmin}
            config={section.predictionResult}
            onConfigChange={onPredictionResultChange}
          />
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={PredictionSection.MTCNN} description={MTCNN_DESCRIPTION_DESCRIPTION}>
        <ConfigurationSubsection title="Minimum Face Size for MTCNN">
          <div className={classes.mtcnnMinFaceSizeSlider}>
            <SliderBar
              min={50}
              max={500}
              step={1}
              value={section.minFaceSize}
              onChange={(v) => setPanelConfigurationSlider(v, "minFaceSize")}
              disabled={!isAdmin}
            />
          </div>
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={PredictionSection.SaveImages} description={SAVE_IMAGES_DESCRIPTION_DESCRIPTION}>
        <SwitchElement
          title="Show Store Images Option"
          checked={section.showStoreImagesOption}
          onChange={(checked) =>
            onConfigurationChange({
              ...section,
              showStoreImagesOption: checked,
            })
          }
          disabled={!isAdmin}
        />
      </ConfigurationSection>
    </div>
  );
};

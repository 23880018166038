import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import { Countdown } from "../../../Utils/Countdown";
import React from "react";
import { SessionInfo } from "../../../../models/aiClient";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    feedback: {
      position: "absolute",
      zIndex: 1,
      padding: "2px 10px 2px 10px",
      borderRadius: "10px !important",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
    feedbackTimer: {
      [theme.breakpoints.down("md")]: {
        marginTop: "-110px",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "-45px",
      },
      left: "1.1%",
    },
    feedbackExtensions: {
      [theme.breakpoints.down("md")]: {
        left: "1.1%",
        marginTop: "-75px",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "-45px",
        right: "1.1%",
      },
    },
    feedbackPhotos: {
      [theme.breakpoints.down("md")]: {
        left: "1.1%",
        marginTop: "-40px",
      },
      [theme.breakpoints.up("md")]: {
        left: "50%",
        marginTop: "-45px",
        transform: "translate(-50%, 0%)",
      },
    },
    content: {
      fontWeight: "bolder",
      // "sans-serif" is the font family the FCM uses. So it needs to be added here to use the same.
      fontFamily: "sans-serif",
    },
  }),
);

interface Props {
  sessionFeedback: SessionInfo;
  photosSent: number;
  currentExtension?: number;
}

export const AIClientModuleFeedback: React.FC<Props> = (props) => {
  const { sessionFeedback, photosSent, currentExtension } = props;

  const theme = useTheme();
  const mobileWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const typographyVariant = mobileWidth ? "body1" : "h6";

  const classes = useStyles();

  return (
    <>
      <div className={classNames(classes.feedback, classes.feedbackPhotos)}>
        <Typography variant={typographyVariant} className={classes.content}>
          Photos sent: {photosSent} (Min: {sessionFeedback.minNumAttemps} Max: {sessionFeedback.maxNumAttemps})
        </Typography>
      </div>

      {currentExtension && (
        <div className={classNames(classes.feedback, classes.feedbackExtensions)}>
          <Typography variant={typographyVariant} className={classes.content}>
            Current extension: {currentExtension}/{sessionFeedback.maxExtensions}
          </Typography>
        </div>
      )}

      <div
        className={classNames(
          classes.feedback,
          mobileWidth ? (currentExtension ? classes.feedbackTimer : classes.feedbackExtensions) : classes.feedbackTimer,
        )}
      >
        <Typography variant={typographyVariant} className={classes.content}>
          <Countdown expireDate={sessionFeedback.expire} />
        </Typography>
      </div>
    </>
  );
};

import React, { useCallback, useEffect, useState } from "react";

interface Props {
  expireDate: Date;
  className?: string;
}

interface TimeLeft {
  minutes: number;
  seconds: number;
}

export const Countdown: React.FC<Props> = (props) => {
  const { expireDate, className } = props;

  const calculateTimeLeft = useCallback((): TimeLeft => {
    const difference = new Date(expireDate).getTime() - new Date().getTime();

    return difference > 0
      ? { minutes: Math.floor(difference / 1000 / 60), seconds: Math.ceil((difference / 1000) % 60) }
      : { minutes: 0, seconds: 0 };
  }, [expireDate]);

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
  }, [expireDate, calculateTimeLeft]);

  return (
    <div className={className}>
      Time left: {("0" + timeLeft.minutes).slice(-2)}:{("0" + timeLeft.seconds).slice(-2)}
    </div>
  );
};

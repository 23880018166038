import { ClientResult, ErrorResult, PredictionResult } from "@yoti/ai-client-module/dist/models/Prediction";
import { ExpectedBrightness, ExpectedLocation, ExpectedResult } from "../../../../models/log";
import { FeedbackInfo, SessionContentResult } from "../../../../models/aiClient";
import React, { useCallback, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { AIClient } from "@yoti/ai-client-module";
import { AIClientModuleFeedback } from "./AIClientFeedback";
import Config from "../../../../config";
import Device from "../../../../config/device";
import { GlobalContext } from "../../../../globalContext";
import { ImageExtensionFormat } from "../../../../models/image";
import { LogService } from "../../../../api/log";
import { PredictionFormulaResult } from "../../../../models/prediction";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: "relative",
      display: "inline-block",
    },
  }),
);

const logService = new LogService(Config.apiUrl);

interface ResultInfo {
  prediction: PredictionFormulaResult;
  score: number;
  formula: string;
}

interface Props {
  expectedResult?: ExpectedResult;
  expectedLocation?: ExpectedLocation;
  expectedBrightness?: ExpectedBrightness;
  debugMode?: boolean;
  showOverlay?: boolean;
  onScan: (
    res: PredictionFormulaResult,
    img?: string,
    sessionId?: string,
    requestId?: string,
    sessionContent?: SessionContentResult[],
  ) => void;
  onError: (e: Error, img?: string) => void;
}

const soundSuccess = new Audio(require("../../../../audio/success.mp3"));
const soundError = new Audio(require("../../../../audio/error.mp3"));

export const AIClientModule: React.FC<Props> = (props) => {
  const { debugMode, showOverlay, onScan, onError, expectedResult, expectedLocation, expectedBrightness } = props;
  const { panelConfigurationData } = React.useContext(GlobalContext);

  const [sessionFeedback, setSessionFeedback] = useState<FeedbackInfo>({ photosSent: 0 });

  // Take image extension format from configuration.
  const { imageExtensionFormat, imageJPEGCompression } = panelConfigurationData.scanConfiguration;
  const format = imageExtensionFormat !== ImageExtensionFormat.Unknown ? imageExtensionFormat : undefined;

  const pictureToSendConfig = panelConfigurationData.scanConfiguration.selectedInputPicture;
  const pictureToSend =
    pictureToSendConfig === "original" || pictureToSendConfig === "cropped" ? pictureToSendConfig : undefined;

  const classes = useStyles();

  const setSessionInfo = useCallback(
    (res: ClientResult, info?: ResultInfo) => {
      if (res.sessionId) {
        logService.setSessionInfo({
          ...res,
          ...info,
          sessionId: res.sessionId,
          expectedResult: expectedResult,
          expectedLocation: expectedLocation,
          expectedBrightness: expectedBrightness,
          device: { ...Device, cameraInfo: undefined }, // The FCM does not returns the camera stream entity so we cannot take the camera info
          imageCompression: imageJPEGCompression,
          imageFormat: format,
        });
      }
    },
    [expectedResult, expectedLocation, expectedBrightness, format, imageJPEGCompression],
  );

  const notifySuccess = useCallback(
    (res: PredictionResult) => {
      soundSuccess.play();
      setSessionInfo(res, res);
      const prediction = res.prediction;
      const content = res.predictionImages;
      onScan(prediction, res.photo?.img, res.sessionId, res.requestId, content);
    },
    [setSessionInfo, onScan],
  );

  const notifyError = useCallback(
    (res: ErrorResult) => {
      soundError.play();
      setSessionInfo(res);
      onError(res.error);
    },
    [setSessionInfo, onError],
  );

  const onUpdateUserFeedback = useCallback(
    (feedbackInfo: FeedbackInfo) => {
      setSessionFeedback(feedbackInfo);
    },
    [setSessionFeedback],
  );

  return (
    <div className={classes.wrapper}>
      <AIClient
        endpoint={panelConfigurationData.scanConfiguration.aiSessionManagerURL}
        isDebug={debugMode ?? panelConfigurationData.scanConfiguration.faceCaptureModuleDebug}
        imageType={pictureToSend}
        relativeMarginCropping={panelConfigurationData.scanConfiguration.relativeMarginPercentageCropping}
        showOverlay={showOverlay ?? panelConfigurationData.scanConfiguration.faceCaptureModuleShowOverlay}
        onSuccess={notifySuccess}
        onError={notifyError}
        onUpdateUserFeedback={onUpdateUserFeedback}
        format={format}
        imageQuality={imageJPEGCompression}
        autocaptureDelay={panelConfigurationData.scanConfiguration.faceCaptureModuleAutocaptureDelay}
        faceSizeDeviation={panelConfigurationData.scanConfiguration.faceCaptureModuleFaceSizeDeviation}
        delay={panelConfigurationData.scanConfiguration.aiClientModuleDelay}
        scoreThreshold={panelConfigurationData.scanConfiguration.faceCaptureModuleScoreThreshold}
        calculateLaplacian={panelConfigurationData.scanConfiguration.aiClientLaplacianEnabled}
      />
      {sessionFeedback.session && (
        <AIClientModuleFeedback
          sessionFeedback={sessionFeedback.session}
          photosSent={sessionFeedback.photosSent}
          currentExtension={sessionFeedback.extensions}
        />
      )}
    </div>
  );
};
